import React, { useCallback, useMemo, useState } from 'react';

import { PARTICIPANTS } from 'mocks/screver-ocx-program/participants';

import Item from './Item';

import { section, listStyle, emptyState } from './styles.module.scss';
import Search from './Search';

const ParticipantsList = () => {
  const participantsArray = useMemo(() => [...PARTICIPANTS], []);
  // const smallArray = useMemo(() => [...participantsArray].slice(0, 7), [
  //   participantsArray,
  // ]);

  const [searchValue, setSearchValue] = useState('');
  const [filteredResults, setFilteredResults] = useState(participantsArray);

  const handleChange = useCallback(
    (e) => {
      setSearchValue(e.target.value);

      if (searchValue !== '') {
        const searchArray = [...participantsArray].filter(
          (item) =>
            item['Name'].toLowerCase().includes(e.target.value.toLowerCase()) ||
            item['Company']
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item['Position']
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
        );
        setFilteredResults(searchArray);
      } else {
        setFilteredResults(participantsArray);
      }
    },
    [participantsArray, searchValue]
  );

  return (
    <section className={section}>
      <Search onChange={handleChange} />
      <ul className={listStyle}>
        {filteredResults.length > 0 ? (
          filteredResults.map((item) => {
            return (
              <li key={item['Name']}>
                <Item item={item} />
              </li>
            );
          })
        ) : (
          <p className={emptyState}>
            It seems we can’t find any results based on your search
          </p>
        )}
      </ul>
    </section>
  );
};

export default ParticipantsList;
