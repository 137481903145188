import React from 'react';

import Icon from 'components/UI/Icon';

import { search } from './styles.module.scss';

const Search = ({ onChange }) => {
  return (
    <label className={search}>
      <Icon name="search-glass" />

      <input
        type="text"
        onChange={onChange}
        placeholder="Search by position, company or name..."
        id="participants-search"
      />
    </label>
  );
};

export default Search;
