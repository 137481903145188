export const PARTICIPANTS = [{
    "Name": "Farah Ghazal",
    "Company": "Volcafe",
    "Position": "Head of HR"
  },
  {
    "Name": "Heiko Böhmer",
    "Company": "Allgäu Wings",
    "Position": "CEO"
  },
  {
    "Name": "Stefan Goetz",
    "Company": "Ruag Schweiz AG",
    "Position": "COO Reha"
  },
  {
    "Name": "Adrian Herzog",
    "Company": "LiveTiles",
    "Position": "Senior Account Manager"
  },
  {
    "Name": "Ahmed Elkammar",
    "Company": "Viahub",
    "Position": "Chief Executive Officer"
  },
  {
    "Name": "Alejandro Otero",
    "Company": "Swarovski",
    "Position": "Global Head of Brand Portfolio Strategy"
  },
  {
    "Name": "Alex Hirter",
    "Company": "ETEL S.A.",
    "Position": "Chief Executive Officer"
  },
  {
    "Name": "Alex Schoepf",
    "Company": "4results",
    "Position": "Chief Executive Officer"
  },
  {
    "Name": "Alexander Konstantinidis",
    "Company": "Teva Pharmaceuticals",
    "Position": "VP, Head of Marketing and Commercial Operations, International Markets"
  },
  {
    "Name": "Alexandra Diening",
    "Company": "EPAM Systems",
    "Position": "Senior Director"
  },
  {
    "Name": "Alina Staikov",
    "Company": "Gynpoint",
    "Position": "Chief Executive Officer"
  },
  {
    "Name": "Alvaro Gianola",
    "Company": "Energy Vault",
    "Position": "Director People Operations Europe"
  },
  {
    "Name": "Ana Maria Zumsteg",
    "Company": "CoFuturum",
    "Position": "Managing Partner. Cofounder"
  },
  {
    "Name": "Andrea Buss",
    "Company": "Brainforce",
    "Position": "Member Management Board"
  },
  {
    "Name": "Andrea Joss",
    "Company": "ISS",
    "Position": "Director of Content"
  },
  {
    "Name": "Andreas Baumann",
    "Company": "Olympus Europa",
    "Position": "Global Head of Marketing Agency"
  },
  {
    "Name": "Andreas Eigenmann",
    "Company": "Unternehmenskommunikation",
    "Position": "Owner"
  },
  {
    "Name": "Andreas Schläpfer",
    "Company": "Geberit AG",
    "Position": "Head of Application Engineering"
  },
  {
    "Name": "Angelo Stilla",
    "Company": "Sparkasse",
    "Position": "Deputy Head of Central Division"
  },
  {
    "Name": "Anja Huber",
    "Company": "ZHAW",
    "Position": "Specialist HR Development / Agile Coach"
  },
  {
    "Name": "Anna Breckwoldt",
    "Company": "Globalance Bank",
    "Position": "Head Marketing and Communications"
  },
  {
    "Name": "Anna Hakobyan",
    "Company": "Pyxis",
    "Position": "Head of Corporate Development"
  },
  {
    "Name": "Anna Kavvasiadi",
    "Company": "",
    "Position": "Designer"
  },
  {
    "Name": "Anna Klebus",
    "Company": "Roche",
    "Position": "Global Lead Patient & Customer Experience. Roche Informatics"
  },
  {
    "Name": "Antje Schenker",
    "Company": "Yolinga",
    "Position": "Chief Executive Officer"
  },
  {
    "Name": "Armin Dittli",
    "Company": "ZHAW",
    "Position": "Director Human Resources"
  },
  {
    "Name": "Berenice Roch",
    "Company": "Human Edge",
    "Position": "Partner / Director Talent Solutions"
  },
  {
    "Name": "Bernhard Heinzler",
    "Company": "Sales Efficiency",
    "Position": ""
  },
  {
    "Name": "Bjorn Lindner",
    "Company": "Planzer",
    "Position": "Head of Innovations"
  },
  {
    "Name": "Bruno Zanella",
    "Company": "Flughafen Zürich",
    "Position": "Head Human Resources"
  },
  {
    "Name": "Christoph Schmid",
    "Company": "CGZ Consulting Gruppe Zürich AG",
    "Position": "Chief Executive Officer"
  },
  {
    "Name": "Christoph von Euw",
    "Company": "Glacier Express",
    "Position": "Chief Operating Officer"
  },
  {
    "Name": "Cinzia Marangoni",
    "Company": "AMAG Leasing",
    "Position": "Head of Marketing & Communication"
  },
  {
    "Name": "Constantin Papadopoulos",
    "Company": "snipers.sale",
    "Position": "Co-Founder"
  },
  {
    "Name": "Coralie Klaus Boecker",
    "Company": "Flughafen Zürich",
    "Position": "Head Marketing & Digital"
  },
  {
    "Name": "Damiano Nardone",
    "Company": "PM Care Systems",
    "Position": "Deputy Managing Director"
  },
  {
    "Name": "Damir Petrinic",
    "Company": "Johnson & Johnson",
    "Position": "EMEA Commercial & Customer Engagement Manager, Global Digital Solutions"
  },
  {
    "Name": "Dario Albanesi",
    "Company": "EPAM Systems",
    "Position": "Head of Research & Insights"
  },
  {
    "Name": "David Howes",
    "Company": "Heubach",
    "Position": "Head of Digital Innovation"
  },
  {
    "Name": "David Wood",
    "Company": "Abergeldie Complex Infrastructure",
    "Position": "Chief Financial Officer"
  },
  {
    "Name": "Diana Risola",
    "Company": "Miele",
    "Position": "Regional Director Human Resources DACH"
  },
  {
    "Name": "Dirk Hofman",
    "Company": "EAO",
    "Position": "Chief Financial Officer"
  },
  {
    "Name": "Diwakar Loshali",
    "Company": "Oerlikon",
    "Position": "Global Head HR Tech & Operations"
  },
  {
    "Name": "Domenic Buschor",
    "Company": "labor team w",
    "Position": "Head of Marketing"
  },
  {
    "Name": "Dominik Gmür",
    "Company": "Savyx",
    "Position": "Global Head of Finance"
  },
  {
    "Name": "Dorota Zoldosova",
    "Company": "Sulzer",
    "Position": "Head of Marketing and Communications"
  },
  {
    "Name": "Duncan Trew",
    "Company": "Novartis",
    "Position": "Director Customer Experience and Engagement, Regional Director Business Excellence Cell and Gene Therapies Europe"
  },
  {
    "Name": "Ece Ergin",
    "Company": "MET Holding",
    "Position": "Interim Group Head of HR"
  },
  {
    "Name": "Elisabeth Schmid",
    "Company": "BSI",
    "Position": "Strategic Partnership Development Manager"
  },
  {
    "Name": "Erik Neumann",
    "Company": "Richmond Events",
    "Position": "Chief Executive Officer"
  },
  {
    "Name": "Eva Ammann",
    "Company": "STC Switzerland Travel Centre",
    "Position": "Director Corporate Services, deputy CEO"
  },
  {
    "Name": "Fang Liu",
    "Company": "Credit Suisse",
    "Position": "Digital Health PM"
  },
  {
    "Name": "Francesco D’Ambrosio",
    "Company": "Controlant",
    "Position": "Customer Experience Manager"
  },
  {
    "Name": "Gaetano Mecenero",
    "Company": "Futurae Technologies",
    "Position": "Chief of Staff"
  },
  {
    "Name": "Gilberto Loacker",
    "Company": "Alturos Destinations",
    "Position": "President & Founder"
  },
  {
    "Name": "Guy Leu",
    "Company": "Sintetica",
    "Position": "Director Marketing & Sales"
  },
  {
    "Name": "Hana Nendl",
    "Company": "Nestlé Health Science",
    "Position": "Global Digital HCP Engagement Lead"
  },
  {
    "Name": "Hannes Flechsig",
    "Company": "Bayer",
    "Position": "Marketing Lead"
  },
  {
    "Name": "Hanspeter Flury",
    "Company": "Schützen Rheinfelden Klinik und Hotels",
    "Position": "Chief Physician, Clinic Director and Chairman of the Management Board"
  },
  {
    "Name": "Hanspeter Steiner",
    "Company": "MCAM Services",
    "Position": "Chief Financial Officer"
  },
  {
    "Name": "Harald Rubin",
    "Company": "AddExpert",
    "Position": "Chief Executive Officer"
  },
  {
    "Name": "Heiko Schickel",
    "Company": "PhenomX Health SA / Brainforce",
    "Position": "VR / GL"
  },
  {
    "Name": "Heinz Brägger",
    "Company": "AddExpert",
    "Position": "Senior Partner"
  },
  {
    "Name": "Henry Detering",
    "Company": "Neuberger Berman",
    "Position": "Chief Marketing Officer"
  },
  {
    "Name": "Intan Vermeer",
    "Company": "Digitec Galaxus",
    "Position": "Head of Customer Service"
  },
  {
    "Name": "Iva Marić",
    "Company": "Viahub",
    "Position": "Chief Product Manager"
  },
  {
    "Name": "Jan Keller",
    "Company": "Google",
    "Position": "Technical Program Manager"
  },
  {
    "Name": "Jann Tadorian",
    "Company": "Inplenion",
    "Position": "Chief Executive Officer"
  },
  {
    "Name": "Jannine Hodel",
    "Company": "Stadtcasino Baden",
    "Position": "HR Director"
  },
  {
    "Name": "Jannis Koasidis",
    "Company": "Galderma",
    "Position": "Global Digital Operations Director"
  },
  {
    "Name": "Jasmin Sitton",
    "Company": "RehaCity",
    "Position": "Chief Executive Officer"
  },
  {
    "Name": "Joachim Leonhartsberger",
    "Company": "UBS",
    "Position": "Strategic Relationship Manager"
  },
  {
    "Name": "Kai Ren",
    "Company": "Creditworld",
    "Position": "Partner"
  },
  {
    "Name": "Karin Haldimann",
    "Company": "Stiftung Heilsarmee Schweiz",
    "Position": "Head of Finance"
  },
  {
    "Name": "Katrin Riedel",
    "Company": "frauenarztzentrum aargau",
    "Position": "Coordinator"
  },
  {
    "Name": "Klaus Tiroch",
    "Company": "Herz-zentrum",
    "Position": "Medical Director"
  },
  {
    "Name": "Konrad Balsiger",
    "Company": "Kedaka",
    "Position": "Founder"
  },
  {
    "Name": "Laila Douieb",
    "Company": "EF Education First",
    "Position": "Director of Recruitment & Employee Development"
  },
  {
    "Name": "Laura Gil Garcia",
    "Company": "IBM iX",
    "Position": "Associate Director"
  },
  {
    "Name": "Laura Lenzo",
    "Company": "Roche",
    "Position": "Scientific Communications Manager"
  },
  {
    "Name": "Liam Quinn",
    "Company": "Richmond Events",
    "Position": "IT Director"
  },
  {
    "Name": "Lorenz Libert",
    "Company": "RxY",
    "Position": "Head of Oncology"
  },
  {
    "Name": "Luana Grossi",
    "Company": "Verlingue",
    "Position": "Senior Relationship Leader"
  },
  {
    "Name": "Luca Todeschini",
    "Company": "Augen Glattzentrum ONO",
    "Position": "Chief Executive Officer"
  },
  {
    "Name": "Maciej Czerwinski",
    "Company": "ServiceNow",
    "Position": "Senior Director Customer Workflow Solution Sales"
  },
  {
    "Name": "Manuel Frietsch",
    "Company": "Roche",
    "Position": "Head of Marketing Operations & Communications"
  },
  {
    "Name": "Manuel Mayr",
    "Company": "Medgate",
    "Position": "Product Manager"
  },
  {
    "Name": "Manuela Van der Werf",
    "Company": "Minger Treuhand",
    "Position": "Senior Account Manager"
  },
  {
    "Name": "Marc Danner",
    "Company": "Clariant AG",
    "Position": "Head of Global Finance Services"
  },
  {
    "Name": "Marc Hauser",
    "Company": "Erfolgswelle",
    "Position": "Founder & Owner"
  },
  {
    "Name": "Marc Liew",
    "Company": "Zurich Insurance Company",
    "Position": "Head channels"
  },
  {
    "Name": "Marco Andre",
    "Company": "Novartis",
    "Position": "Global Head of Employee Experience"
  },
  {
    "Name": "Marco Di Sante",
    "Company": "Sanofi",
    "Position": "General Manager Consumer Healthcare"
  },
  {
    "Name": "Marco Schaffner",
    "Company": "Emil Egger",
    "Position": "Head of HR"
  },
  {
    "Name": "Mariam Sultani",
    "Company": "Capgemini Invent",
    "Position": "Partner, Operations Practice"
  },
  {
    "Name": "Mario Sager",
    "Company": "MS Sports",
    "Position": "Chief Executive Officer"
  },
  {
    "Name": "Mario Schaller",
    "Company": "Heubach",
    "Position": "CoE Rewards"
  },
  {
    "Name": "Marko Wolter",
    "Company": "DMG MORI",
    "Position": "Digital Transformation Lead HR"
  },
  {
    "Name": "Markus Amstutz",
    "Company": "fenaco",
    "Position": "Member of the board; Head Brokerage & Riskmanagement"
  },
  {
    "Name": "Markus Ruchty",
    "Company": "Bayer",
    "Position": "Head of Marketing"
  },
  {
    "Name": "Markus Wyss",
    "Company": "CAMAG",
    "Position": "Chief Executive Officer"
  },
  {
    "Name": "Martin Wiederkehr",
    "Company": "Sika",
    "Position": "Finance Director"
  },
  {
    "Name": "Michael Kistler",
    "Company": "Rhätische Bahn",
    "Position": "Head of Sales Management"
  },
  {
    "Name": "Michael Reuteler",
    "Company": "Willers",
    "Position": "Regional Leader Zurich"
  },
  {
    "Name": "Milo Gerisch",
    "Company": "SBB Cargo International",
    "Position": "Key Account Manager"
  },
  {
    "Name": "Mohsin Kazmi",
    "Company": "PME FOCUS",
    "Position": "Scaling Up Coach"
  },
  {
    "Name": "Monika Wodzynski",
    "Company": "Loylogic",
    "Position": "Chief People Officer"
  },
  {
    "Name": "Muriel Mercier",
    "Company": "SBB Cargo International",
    "Position": "Marketing & External Communication"
  },
  {
    "Name": "Nadia Moser Ritler",
    "Company": "BKW",
    "Position": "Head of Strategy and Transformation for BKW Energy Markets & Group Services"
  },
  {
    "Name": "Naomi Zünd",
    "Company": "Consilia Treuhand",
    "Position": "Executive Assistant of CEO"
  },
  {
    "Name": "Nicolas Perez",
    "Company": "Lonza",
    "Position": "Director, Global Client Partner Life Sciences"
  },
  {
    "Name": "Nik Hauser",
    "Company": "frauenarztzentrum aargau",
    "Position": "Owner"
  },
  {
    "Name": "Nils Bulling",
    "Company": "Avaloq",
    "Position": "Head Strategic Innovation. Ecosystem & Digital Assets"
  },
  {
    "Name": "Nina Grosse",
    "Company": "iDeals",
    "Position": "VP of People & Culture"
  },
  {
    "Name": "Oksana Rud",
    "Company": "General Dynamics European Land Systems (GDELS)",
    "Position": "Marketing and Communication Specialist"
  },
  {
    "Name": "Outi Kettunen-Walter",
    "Company": "3A Composites Core Materials / Airex AG",
    "Position": "Marketing Manager"
  },
  {
    "Name": "Paskal Weller",
    "Company": "Europa-Park",
    "Position": "Customer Insights Manager"
  },
  {
    "Name": "Patricia Lemattre Stoeckel",
    "Company": "Vente-Privee Devient Veepee",
    "Position": "Managing Director Veepee Switzerland (eBoutic) / Group Business Executive member"
  },
  {
    "Name": "Patrick Leimgruber",
    "Company": "Sanofi",
    "Position": "Global Brand Lead"
  },
  {
    "Name": "Petra Stilla",
    "Company": "Sparkasse",
    "Position": "Manager"
  },
  {
    "Name": "Pierre-Alain Aeby",
    "Company": "Erni Consulting",
    "Position": "Chief Financial Officer"
  },
  {
    "Name": "Pietro Petriella",
    "Company": "Dr. Wild & Co. AG",
    "Position": "Head International Sales & Marketing"
  },
  {
    "Name": "Reto\tSchnellmann",
    "Company": "ZHAW",
    "Position": "Head of Finances & Services"
  },
  {
    "Name": "Robert Tadic",
    "Company": "Trianon",
    "Position": "Head of Sales"
  },
  {
    "Name": "Roger Sutter",
    "Company": "Kuehne + Nagel Management",
    "Position": "Group Treasurer"
  },
  {
    "Name": "Sabine Burgener",
    "Company": "SWICA",
    "Position": "Head of Customer Experience Management"
  },
  {
    "Name": "Sabine Huemer-Kilchoer",
    "Company": "Demant Schweiz",
    "Position": "General Manager Group Services"
  },
  {
    "Name": "Samira Klijnsma",
    "Company": "World Vision Schweiz & Liechtenstein",
    "Position": "Marketing and Innovations Coordinator"
  },
  {
    "Name": "Sandro Gerber",
    "Company": "Holibon",
    "Position": "Chairman"
  },
  {
    "Name": "Serge Altmann",
    "Company": "ZURZACH Care",
    "Position": "Group CEO"
  },
  {
    "Name": "Silke Liebig",
    "Company": "Privatklinik Lindberg",
    "Position": "Head of Marketing & Communication"
  },
  {
    "Name": "Simone Maisto",
    "Company": "Unipharma SA",
    "Position": "Regional Managing Director"
  },
  {
    "Name": "Stijn Vander Straeten",
    "Company": "Swiss Crypto Vault",
    "Position": "Chief Financial Officer"
  },
  {
    "Name": "Tal Cohen",
    "Company": "Swiss Life Select",
    "Position": "Teamleiter Ostschweiz"
  },
  {
    "Name": "Tania Moser",
    "Company": "Pets Deli",
    "Position": "CEO & Managing Director (R&D. Finance. Operations. Sales)"
  },
  {
    "Name": "Thomas Grom",
    "Company": "CoFuturum",
    "Position": "Co-Founder and Managing Partner"
  },
  {
    "Name": "Thomas Leber",
    "Company": "Frontiers",
    "Position": "Director Customer Experience"
  },
  {
    "Name": "Tytus Litynski",
    "Company": "Alfasigma Schweiz",
    "Position": "General Manager"
  },
  {
    "Name": "Urs Meier",
    "Company": "Bruker BioSpin",
    "Position": "Director Customer Experience & Services"
  },
  {
    "Name": "Valentina Stont",
    "Company": "PwC",
    "Position": "Advisory Services"
  },
  {
    "Name": "Vera Schmitz",
    "Company": "General Dynamics European Land Systems (GDELS)",
    "Position": "Junior Manager IB&S Product Content"
  },
  {
    "Name": "Vladimir Dubal",
    "Company": "Local",
    "Position": "Head of Product - New Business"
  },
  {
    "Name": "Vladimir Hana",
    "Company": "Takeda",
    "Position": "OCE Centre of Excellence Head"
  },
  {
    "Name": "Walter Bernardi",
    "Company": "",
    "Position": "Privatier"
  },
  {
    "Name": "Wolfgang Schäfer",
    "Company": "ZHAW",
    "Position": "Senior Researcher & Program Leader Customer Experience Management"
  },
  {
    "Name": "Xenia Schwarzbach",
    "Company": "Elektro-Material",
    "Position": "Head of HR"
  },
  {
    "Name": "Yvonne Amstutz-Hofmann",
    "Company": "Amstutz Coaching & Consulting",
    "Position": "Chief Executive Officer"
  },
  {
    "Name": "Zaiga Kalve",
    "Company": "Oel-Pool",
    "Position": "Chief Financial Officer"
  }
];
