import React from 'react';
import classnames from 'classnames';

import { name, description } from './styles.module.scss';

const Item = ({ item }) => {
  return (
    <div>
      {item['Name'] ? (
        <p className={classnames(name, 'font-epilogue')}>{item['Name']}</p>
      ) : (
        <></>
      )}

      {item['Company'] ? (
        <p className={classnames(description, 'font-aeroport')}>
          {item['Company']}
        </p>
      ) : (
        <></>
      )}

      {item['Position'] ? (
        <p className={classnames(description, 'font-aeroport')}>
          {item['Position']}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Item;
