import React from 'react';

import HeaderCommon from 'sections/ScreverOcxProgram/HeaderCommon';
import FooterCommon from 'sections/ScreverOcxProgram/FooterCommon';
import ParticipantsList from 'sections/ScreverOcxProgram/ParticipantsList';
import SEO from 'sections/Layout/SEO';
import OcxHelmet from 'sections/Layout/SEO/OcxHelmet';

import 'sections/ScreverOcxProgram/common.scss';

const PAGES = [
  {
    title: 'Program',
    url: '/events/screver-ocx-program/program',
  },
  {
    title: '✌🏻Feedback',
    url: '/events/screver-ocx-program/feedback',
  },
  {
    title: 'Casino instructions',
    url: '/events/screver-ocx-program/casino-instructions',
  },
];

const seo = {
  title: 'OCX Event | Participants | Screver Feedback Management Solution',
  metaDesc:
    'Discover the list of participants for the upcoming Screver OCX event. See who will be joining us. We’ll share some exciting news and showcase how AI shapes VoC',
  opengraphUrl: 'https://screver.com/events/screver-ocx-program/participants/',
  image:
    'https://wp.screver.com/wp-content/uploads/2023/08/screver-ocx-meta-img.jpeg',
};

const ParticipantsPage = () => {
  return (
    <>
      <OcxHelmet />

      <SEO seo={seo} themeColor={'#151517'} />

      <div className="scr-ocx-program">
        <HeaderCommon title="Participants" />

        <main>
          <div className="ocx-prog-wrap">
            <ParticipantsList />
          </div>
        </main>

        <FooterCommon linksToPages={PAGES} />
      </div>
    </>
  );
};

export default ParticipantsPage;
